import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router/index";
import store from "@/store/index.js";
import VueMask from 'v-mask'

// //This will check if user is logged-in or not.
// router.beforeEach((to, from, next) => {
//   if (to.name !== "login" && !store.state.isAuthenticated) next({ name: "login" });
//   else next();
// })

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && store.getters.user == null) {
    //Redirect if user is not logged in
    next({ name: "login" });
  } else {
    if (store.state.unSavedChanges) {
      //Reset the flag
      //Ask for confirmation
      const answer = window.confirm(
        "You have unsaved changes. Discard the changes?"
      );

      if (!answer) {
        return next(false);
      } else {
        store.state.unSavedChanges = false;
        next();
      }
    } else {
      next();
    }
  }
});

Vue.use(VueAxios, axios);
Vue.use(router);

Vue.config.productionTip = false;

Vue.use(VueMask);

new Vue({
  router: router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
