import axios from "axios";
//console.log(process.env.VUE_APP_API_BASE_URI)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URI;
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.timeout = 1000;

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URI,

    headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Access-Control-Allow-Origin':'*'
        //'Authorization': 'Bearer ' + store.getters.accessToken
        //'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
});

export default apiClient;