import apiClient from "./apiClient";
//import constant from './constants';

export default {
  getLookup(LookupKey) {
    return apiClient.post("list/getLookup", {
      LookupKey: LookupKey,
    });
  },
  getPatientSearchMasters() {
    return apiClient.post("list/patient-search-masters", null);
  },

  getDoctorsList(data) {
    return apiClient.post("list/doctor-list", data);
  },

  getDiagnosisList(data) {
    return apiClient.post("list/diagnosis-list", data);
  },
  getEventTypeList() {
    return apiClient.post("scheduling/eventtypelist", null);
  },
  getFrequencyUnitList() {
    return apiClient.post("list/getLookup", {
      LookupKey: "SCHEDULE FREQUENCY",
    });
  },
  getRemoteServiceList() {
    return apiClient.post("scheduling/remoteServiceList", null);
  },
  getInsuranceList(data) {
    return apiClient.post("list/insuranceList", data);
  },
  getDeviceType(data) {
    return apiClient.post("list/getDeviceType", data);
  },
  getDeviceModelList(data) {
    return apiClient.post("list/deviceModelList", data);
  },
  getRolelList() {
    return apiClient.post("list/userRoleList", null);
  },
  getFavoritePatientList() {
    return apiClient.post("list/getfavoritePatientList", null);
  },

  //Extended Care Facility--------------
  getExtndCareFacilityById() {
    return apiClient.post("list/GetExtndCareFacilityById", null);
  },
  getExtndCareFacilityList(data) {
    return apiClient.post("list/GetExtndCareFacilityList", data);
  },
  //------------------------------------
};
