import Vue from "vue";
import Vuex from "vuex";
//import axios from 'axios'
//import createPersistedState from "vuex-persistedstate";

import listAPI from "@/api/listAPI.js";
//import alerts from "../common/Alerts";
//import patientAPI from "@/api/patientAPI.js";

Vue.use(Vuex);

//let dialog = { value: "Cost", order: "" };

export const store = new Vuex.Store({
  state: {
    dialogDoctor: false,
    arrStates: [],
    arrPatientStayType: [],
    //patientId: 192, /1
    patientId: null,
    patientInfo: { first_name: null, last_name: null },
    notify: { visible: false, type: null, message: null },
    isAuthenticated: false,
    user_details: null,
    redirectURL: "search",
    unSavedChanges: false,

    arrFavoritePatientList: [],

    //--Permission Management--
    allowAppointmentReport: false,
    allowUploadDetails: false,
    allowUserManagement: false,

    required: {
      visible: true,
      type: "error",
      message: "Please provide all required fields.",
    },
  },
  getters: {
    user() {
      return JSON.parse(sessionStorage.getItem("user"));
    },
    getDialogDoctor(state) {
      return state.dialogDoctor;
    },
    getStates(state) {
      listAPI
        .getPatientSearchMasters()
        .then((response) => {
          state.arrStates = response.data.results.States;
          // this.vrStatus = response.data.results.Status;
          // this.vrRemoteService = response.data.results.RemoteServices;
        })
        .catch((error) => {
          console.log(error);
        });

      return state.arrStates;
    },
    getPatientStayType(state) {
      return state.arrPatientStayType;
    },
    favoritePatientList(state) {
      return state.arrFavoritePatientList;
    },
  },
  actions: {
    dialogDoctor(context) {
      context.commit("dialogDoctor");
    },
    getStates(context) {
      context.commit("getStates");
    },
    getPatientStayType(context) {
      context.commit("getPatientStayType");
    },
    getFavoritePatientList(context) {
      context.commit("getFavoritePatientList");
    },
  },
  mutations: {
    dialogDoctor(state, data) {
      state.dialogDoctor = data.show;
    },
    getStates(state) {
      listAPI
        .getPatientSearchMasters()
        .then((response) => {
          state.arrStates = response.data.results.States;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPatientStayType(state) {
      listAPI
        .getLookup("PATIENT_STAY_TYPE")
        .then((response) => {
          state.arrPatientStayType = response.data.results.Lookup;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFavoritePatientList(state) {
      listAPI
        .getFavoritePatientList()
        .then((response) => {
          state.arrFavoritePatientList = response.data.results.patient;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});

export default store;
