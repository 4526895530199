<template>
  <v-app id="app">
    <keep-alive include="patientsearch,report-appointment,report-enrollment">
      <router-view></router-view>
    </keep-alive>
    <v-main></v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({}),
};
</script>

<style>
.v-slide-group__content {
  justify-content: center !important;
}
h3 {
  color: #1b67ac;
  font-weight: 500;
}
body {
  padding-top: 20px;
}
/* .theme--light.v-card {
  background: #f5f8fa;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px #a1a1a1;
}  */
</style>
<style scoped lang="scss">
::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>
<style>
.required label::after {
  content: "*";
  color: red;
  font-weight: bold;
}
.elevation-1 table th {
  background-color: #dee8f1 !important;
}
</style>
