import Vue from "vue";
import VueRouter from "vue-router";
//import ProcessTitle from '../components/process/title.vue'

const routes = [
  {
    name: "login",
    path: "/",
    alias: "/login",
    //component: ProcessTitle
    component: () => import("@/components/login/login.vue"),
  },
  // {
  //     name:'login',
  //     path:'/login',
  //     component: () => import('@/components/login/login.vue')
  // },
  {
    name: "patientsearch",
    path: "/search",
    component: () => import("@/components/patient/patientsearch.vue"),
  },
  {
    name: "patient",
    path: "/patient",
    component: () => import("@/components/patient/information.vue"),
  },
  {
    name: "select",
    path: "/select",
    component: () => import("@/components/common/select.vue"),
  },
  {
    name: "scheduling",
    path: "/scheduling",
    component: () => import("@/components/patient/scheduling.vue"),
  },
  {
    name: "insurance",
    path: "/insurance",
    component: () => import("@/components/patient/insurance.vue"),
  },
  {
    name: "devices",
    path: "/devices",
    component: () => import("@/components/patient/devices.vue"),
  },
  {
    name: "report-appointment",
    path: "/report-appointment",
    component: () => import("@/components/reports/appointment.vue"),
  },
  {
    name: "report-enrollment",
    path: "/report-enrollment",
    component: () => import("@/components/reports/enrollment.vue"),
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("@/components/admin/users.vue"),
  },
  {
    name: "change-password",
    path: "/change-password",
    component: () => import("@/components/user/changepassword.vue"),
  },
  {
    name: "upload-patient-details",
    path: "/upload-patient-details",
    component: () => import("@/components/admin/uploadPatientDetail.vue"),
  },
  {
    name: "notify-patient",
    path: "/notify-patient",
    component: () => import("@/components/patient/notify-patient.vue"),
  },
];

// const router = createRouter({
//     history:createWebHistory(),
//     routes
// });

Vue.use(VueRouter);
const router = new VueRouter({
  routes: routes,
  mode: "history",
});

export default router;
